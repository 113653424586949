import React, { useContext } from 'react';
import styled from 'styled-components';
import { Container, ContentHolder } from './Common';
import Constants, { TextBody } from './Constants';
import { PageContext } from './App';
import { ASSETS_SERVER } from '../lib/Api';

const Alert2Container = styled(ContentHolder)`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
max-width:${Constants.LG}px;

`;

const AlertText = styled.div`
 
color:${Constants.GREY_7}; 
text-align:center;
padding:25px;
max-width:${Constants.LG}px;
${TextBody}

`;


const Image = styled.img`
margin-top:-70px;
max-width:140px;
@media (max-width:${Constants.SM}px) {
  margin-top:-35px;
max-width:70px
      
    }

`;

export default () => {
  const content = useContext(PageContext);
  if (!content.alert2) {
    return null;
  }

  return (
    <Container background="#444444">
      <Alert2Container>
        <Image src={`${ASSETS_SERVER}/images/${content.alert2.image}`} />
        <AlertText style={{ color: '#ffffff' }} className="alert2" dangerouslySetInnerHTML={{ __html: content.alert2.text }} />

      </Alert2Container>

    </Container>
  );
};
