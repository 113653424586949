import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import ReactGA from 'react-ga';
import Routes from './routes';
import { PLATFORM_cardinalhealth, PLATFORM_hackensack } from './lib/Api';

let googleTrackingId = 'UA-162429907-1';
const platform = process.env.PLATFORM;
if (platform === PLATFORM_cardinalhealth) {
  googleTrackingId = 'UA-176591295-1';
}
if (platform === PLATFORM_hackensack) {
  googleTrackingId = 'UA-176567309-1';
}
ReactGA.initialize(googleTrackingId, { debug: false });
render(<BrowserRouter><Routes /></BrowserRouter>, document.getElementById('root'));
