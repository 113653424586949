import React from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

export default (props) => {
  const track = (path) => {
    ReactGA.event({
      category: 'PDF_VIEW',
      action: 'Open PDF',
      label: path,
    });
  };


  if (!props.to || props.to == 'undefined') {
    return <div {...props} />;
  }

  if (/^(http)/i.test(props.to)) {
    return <a target="_blank" {...props} href={props.to} />;
  }
  if (/^(#)/.test(props.to)) {
    return <a {...props} href={props.to} />;
  }
  let path = props.to;
  if (!path) {
    return <Link {...props} />;
  }

  const newProps = { ...props };
  if (path.indexOf('pdf') != -1) {
  //  path = `server/${path}`;
    newProps.to = path;
    return (
      <a
        {...newProps}
        onClick={() => {
          track(path);
        }}
        title={path}
        target="_blank"
        href={newProps.to}
      />
    );
  }


  if (''.charAt(0) !== '/') {
    path = `/${path}`;
    const newProps = { ...props };
    newProps.to = path;
    return <Link {...newProps} />;
  }
};
