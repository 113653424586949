import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { PageContext } from './App';
import { ASSETS_SERVER } from '../lib/Api';
import Constants, {
  TextAlert, Title1, Title2, TextBody, TextLink,
} from './Constants';
import {
  Container, VideoLink, ContentHolder, GhostButton,
} from './Common';
import Anchor from './Anchor';




const ImageHolder = styled.div`
padding-top:20px;
display:flex;
align-items:center;
justify-content:center;
`;

const Image = styled.img`
  
  
  width:100%;
 height:auto;


`;

const Caption1 = styled.div`
 
  color:${Constants.WHITE};
  margin-top:10px;
  margin-bottom:20px;
  ${Title1}

`;
const Caption2 = styled.div`
 
  color:${Constants.WHITE};
  margin-bottom:10px;
  ${Title2}
 
 
`;
const VideoText = styled.div`
  
  color:${Constants.WHITE};
   padding-bottom:30px;
  ${TextBody} 
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) 
{
  max-width:650px;
}

`;

const LinksHolder = styled(ContentHolder)`
 flex-wrap:wrap;

`;

const VideoContent = styled(ContentHolder)`
   
    padding:20px;
    padding-bottom:40px;
    display:flex;
    align-content:center;
    justify-content:space-between;
    @media (max-width:${Constants.SM}px) {
      flex-wrap:wrap-reverse;
    }
`;

const InnerContainer = styled.div`
  flex:.9;
    padding-right:0px;
    padding-bottom:20px;
`;


const FindMoreLink = styled(Anchor)`
${TextLink};
color:${Constants.WHITE};
text-decoration:underline;
 
`;

const FindMore = styled.div`
${TextLink};
color:${Constants.WHITE};
margin-top:20px;
`;

export default () => {
  const content = useContext(PageContext);
  if (!content.video_links) {
    return null;
  }
  if (content.video_links.length === 0) {
    return null;
  }

  const [selectedVideo, setSelectedVideo] = useState(content.video_links[0]);


  return (
    <Container style={{ marginTop: -40 }} background="transparent">
      <LinksHolder>
        {content.video_links.map((link) => {
          const { title } = link;
          return (
            <VideoLink
              onClick={() => {
                setSelectedVideo(link);
              }}
              selected={title === selectedVideo.title}
              key={title}
            >
              {title}
            </VideoLink>
          );
        })}
      </LinksHolder>
      <Container gradient={content.colors.gradient_2}>
        <VideoContent>
          <InnerContainer>
            <Caption1>{selectedVideo.caption1}</Caption1>
            <Caption2>{selectedVideo.caption2}</Caption2>
            <VideoText dangerouslySetInnerHTML={{ __html: selectedVideo.text }} />
            {selectedVideo.button_text && (
            <Anchor to={`${selectedVideo.link_name}`}>
              {' '}
              <GhostButton>{selectedVideo.button_text}</GhostButton>
            </Anchor>
            )}

            <FindMore>
              <FindMoreLink to={`#${selectedVideo.title}`}>Find more</FindMoreLink>
            </FindMore>

          </InnerContainer>

          <ImageHolder>
            <Image src={`${ASSETS_SERVER}/images/${selectedVideo.image}`} />
          </ImageHolder>
        </VideoContent>
      </Container>
    </Container>
  );
};
