import React, { useContext } from 'react';
import styled from 'styled-components';

import TopSection from './TopSection';
import Sayings from './Sayings';
import VideoLinks from './VideoLinks';
import Sections from './Sections';
import Footer from './Footer';
import { PageContainer, GreenBar } from './Common';
import Alert2 from './Alert2';
import ThankYou from './ThankYou';
import SharePhoto from './SharePhoto';
import ToTopButton from './ToTopButton';
import { PageContext } from './App';


export default () => (
  <PageContainer>

    <ToTopButton />
   
    <TopSection   />


    <VideoLinks />
    <Alert2 />
    <ThankYou />
    <SharePhoto />
    <Sections content={useContext(PageContext)} />
    <GreenBar />
    <Footer />
  </PageContainer>
);
