import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  TitleStyle1, Container, HomeButton, PageHolder,
} from '../Common';
import Constants from '../Constants';
import Api from '../../lib/Api';
import { InnerPageContext } from './L2Page';


const VideoHolder = styled.div`
 padding:56.25% 0 0 0;position:relative;
 @media (max-width:${Constants.SM}px) {
    
  
}
 max-width:${Constants.LG}px;

`;


const IFrame = styled.iframe`
 position:absolute;top:0;left:0;width:100%;height:100%;
`;

const Content = styled.div`
margin-top:40px;
font-size:20px;
`;


export default () => {
  const page = useContext(InnerPageContext);

  const [content, setContent] = useState('');


  useEffect(() => {
    if (page.content_html) {
      Api.getHtmlContent(page.content_html).then(({ content }) => {
        setContent(content);
      }).catch((err) => {});
    } else {
      setContent(page.content);
    }
  }, []);

  return (
    <Container>
      <PageHolder>
        <TitleStyle1 style={{ fontSize: 40 }}>
          {page.title}
        </TitleStyle1>
        <VideoHolder>


          <IFrame
            title="vimeo-player"
            src={page.link}
            width="100%"
            height="420"
            frameBorder="0"
            allowFullScreen
          />
        </VideoHolder>
        <Content dangerouslySetInnerHTML={{ __html: content }} />

      </PageHolder>
    </Container>
  );
};
