import React, {
  useState,
  useEffect,
} from 'react';
import styled, {
  keyframes,
} from 'styled-components';


const growTo = 1.2;
const growFrom = 0.8;


const grow = keyframes`
0% {transform:scale(${growFrom}); opacity:.5   }
25% {transform:scale(${growTo}); opacity:1  }
50% {transform:scale(${growTo});opacity:1  }
75% {transform:scale(${growFrom}); opacity:.8 }
100% {transform:scale(${growFrom}); opacity:.5 }


`;


const InnerCircle2 = styled.div`
background-color:rgba(89, 155, 49, .6);
height:170px;
width:170px;
border-radius:50%;
display:flex;
justify-content:center;
align-items:center;
opacity:1;
`;

const InnerCircle1 = styled.div`
 
height:200px;
width:200px;
border-radius:50%;
display:flex;
justify-content:center;
align-items:center;
background-color:rgba(89, 155, 49, .5);
`;

const Circle = styled.div`
display:flex;
justify-content:center;
align-items:center;
animation: ${grow} 20s ease infinite ;
transform:scale(${growFrom});
`;

const Container = styled.div`
width:100%;
display:flex;
justify-content:center;
align-items:center;
}`;

const TextBox = styled.div`
text-align:center;
`;

const Counter = styled.div`
font-weight:bold;
color:#ffffff;
text-align:center;
font-size:50px;
content:"";`;

const Text = styled.div`
font-size:22px;
font-weight:bold;
color:#ffffff;
content:"";
text-align:center;`;


export default () => {
  let frameCounter = 1;
  let ccCounter = 0;
  let secCounter = 0;

  const [text, setText] = useState('');
  const [counter, setCounter] = useState(0);


  const frame = () => {
    const texts = ['Breathe In', 'Hold', 'Breathe Out', 'Hold'];
    setText(texts[secCounter]);
    setCounter(4 - ccCounter);

    if (frameCounter % 5 === 0) {
      secCounter += 1;
      if (secCounter >= 4) {
        secCounter = 0;
      }
    }

    ccCounter += 1;
    if (ccCounter >= 5) {
      ccCounter = 0;
    }


    frameCounter += 1;
  };

  useEffect(() => {
    frame();
    setInterval(frame, 1000);
  }, []);

  return (
    <Container>
      <Circle>
        <InnerCircle1>
          <InnerCircle2>
            <TextBox>
              <Text>{text}</Text>
              <Counter>{counter}</Counter>
            </TextBox>
          </InnerCircle2>
        </InnerCircle1>
      </Circle>
    </Container>
  );
};
