import React, { useState } from 'react';
import styled from 'styled-components';
import { ASSETS_SERVER } from '../../lib/Api';
import { MoreButton, MoreButtonHolder, Container, TitleText, GradientButton } from '../Common';
import Constants, { TextBody } from '../Constants';
import { Link } from 'react-router-dom';


const LongText = styled.div`
padding-top:0px;
 
padding-bottom:0px;
margin:auto;


@media (max-width:${Constants.SM}px) {
 
  
}

`;

const Title = styled.div`
color:#599B31;
font-size:40px;
font-weight:bold;
`;


const Boxes = styled.div``;


const BoxHolder=styled.div`
background-color:${props=>props.bgcolor||"#ffffff"};
padding-right:10px;
  padding-left:10px;
  padding-bottom:30px;
  padding-top:50px;
`;

const BoxDiv = styled.div`

max-width:${Constants.LG}px;
margin:auto;
 
`;

const Hr = styled.div`
border-bottom-width:1px;
border-bottom-style:solid;
border-bottom-color:#599B31;
margin-top:10px;
margin-bottom:10px;
`;

const Content = styled.div` 
color:${Constants.TEXT_COLOR};
 ${TextBody}
margin-bottom:20px;
 

`;
const ImageHolder = styled.div`
 
align-self:center;
margin-right:${props=>props.mode==="1"?"0":"20"}px;
margin-left:${props=>props.mode==="1"?"20":"0"}px;

`;
const Holder = styled.div`
flex:1;
display:flex;
flex-direction:column;
 justify-content:flex-start;

 
`;


const ContentHolder = styled.div`
 
display:flex;
flex-wrap:wrap;
align-items: stretch;
flex-direction:${props=>props.mode==="1"?"row":"row-reverse"};
justify-content:space-between;
@media (max-width:${Constants.SM}px) {
   flex-direction:column-reverse;
}


`;

const Image = styled.img`
max-width:90%;
height:auto;
`;


const ButtonHolder=styled.div`
display:flex;
flex-wrap:wrap;
justify-content:flex-start;
`;

const Button = styled(GradientButton)`

margin-right:20px;
padding:20px;
${TextBody}
margin-top:10px;
flex:1;
min-width:30%;
margin-bottom:10px;
color:${Constants.WHITE};
text-decoration:none;
text-align:center;
vertical-align:middle;
display:flex;
justify-content:center;
align-items:center;
 

`;

const Box = ({ box }) => (
  <BoxHolder bgcolor={box.bgcolor}>
  <BoxDiv>
  
  
    <ContentHolder mode={box.style}>
      <Holder>
      <TitleText style={{textAlign:'left'}} dangerouslySetInnerHTML={{__html:box.title}}></TitleText>
        <Content className="content_list" dangerouslySetInnerHTML={{ __html: box.text }} />
        <ButtonHolder> {box.buttons?.map((button) => <Button key={button.text}  title={button.text} to={button.link_name} >{button.text}</Button>)}</ButtonHolder>
      </Holder>
      <ImageHolder mode={box.style}>
        <Image src={`${ASSETS_SERVER}/images/${box.image}`} />
      </ImageHolder>
    </ContentHolder>
  </BoxDiv>
  </BoxHolder>
);


export default ({ section }) => {

  const [tempBoxes,setTempBoxes]=useState(section.boxes.slice(0,Math.min(1,section.boxes.length)));

  const hasMore = section.boxes.length > 1;

  
  const doMore = () => {
    setTempBoxes(section.boxes);
  };

  const doLess = () => {
    setTempBoxes(section.boxes.slice(0, Math.min(1, section.boxes.length)));
  };





  return (

    <LongText>
      <Boxes>
        {tempBoxes.map((box) => <Box box={box} key={box.title} />)}
      </Boxes>
      
      
        {hasMore && tempBoxes.length <= 1 &&<MoreButtonHolder><MoreButton color="#1592E6" bg="#ffffff" border="#BEBEBE" onClick={doMore}>More</MoreButton></MoreButtonHolder> }
        {hasMore && tempBoxes.length > 1 && <MoreButtonHolder><MoreButton  color="#1592E6" bg="#ffffff" border="#BEBEBE"  onClick={doLess}>Less</MoreButton></MoreButtonHolder>}
      
    </LongText>
    
  );
  

}