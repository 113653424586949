import {
  Switch,
  Route,
} from 'react-router-dom';
import React from 'react';
import App from './components/App';
import L2Page from './components/L2Pages/L2Page';


export default () => (
  <Switch>
    <Route exact path="/" component={App} />
    <Route path="*" exact component={App} />


  </Switch>
);
