import React, { useEffect, useState, createContext } from 'react';
 
import {
  BrowserRouter,
  Switch,
  Route,
  withRouter,
  Link,
} from 'react-router-dom';
import Page from './Page';
import LoadingScreen from './LoadingScreen';
import Api from '../lib/Api';
import L2Page from './L2Pages/L2Page';


const PageContext=createContext({});

export {PageContext}

export default () => {
  const [pageLoaded, setPageLoaded] = useState(false);
  const [error, SetError] = useState(false);
  const [clientData, setClientData] = useState({});
  const [linksDictionary,setLinksDictionary]=useState({});
  const [colors,setColors]=useState({});  
 
 

  useEffect(() => {
    Api.getClient().then((result) => {
      if (result?.content) {
        const content=result.content;
        const pages=result.pages;
        const colors=result.colors;
        setColors(colors);
        setLinksDictionary(pages);
        setClientData(content);
        setPageLoaded(true);
      } else {
        throw new Error(`${result?.ERROR}`);
      }
    }).catch((err) => {
      SetError(`${err}`);
    });
  }, []);


  if (error !== false) {
    return (
      <div style={{
        textAlign: 'center', marginTop: 100, color: 'red', fontSize: 20,
      }}
      >


        {' '}
        {error}
      </div>
    );
  }

  if (pageLoaded === true) {
    return (
      <PageContext.Provider value={{...clientData,linksDictionary,colors}}>
      <Switch>
        <Route exact path="/" render={(props) => <Page />} />
        <Route path='*' exact={true} component={L2Page} />
     </Switch>
      </PageContext.Provider>
    );
  }

  return <LoadingScreen />;
};


