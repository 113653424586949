import React, { useContext } from 'react';
import styled from 'styled-components';
import { PageContext } from './App';
import { ASSETS_SERVER } from '../lib/Api';
import Constants from './Constants';
import { Container } from './Common';

const Sayings = styled.div`
max-width:${Constants.LG - 100}px;
margin:auto;
padding-left:20px;




`;


const Saying = styled.div`
display:flex;
flex-wrap:wrap;
padding-top:50px;
min-height:50px;
flex-direction:${(props) => (props.mode === '1' ? 'row' : 'row-reverse')};

@media (max-width:${Constants.SM}px) {
 padding-bottom:50px; 
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) 
{
  flex:none;
  padding-bottom:100px; 
}

`;

const Holder = styled.div`
display:flex;
flex:1;
min-height:50px;
flex-direction:column;
justify-content:center;
align-items:center;
margin-right:10px;
margin-left:10px;
max-width:800px;



`;

const Text = styled.div`
color:${Constants.TEXT_COLOR};
font-style:italic;
font-size:22px;

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) 
{
  flex:none;
  max-width:400px;
}

`;

const BigQuote = styled.div`
color:#599B31;
font-family:Times;
font-weight:bold;
font-style:italic;
font-size:200px;
float: left;
  line-height: 10px;
  padding-top: 40px;
  padding-right:15px;
  @media (max-width:${Constants.XS}px) {
    display:none;
    
  
}


`;

const TextHolder = styled.div`
display:flex;

`;


const BigQuote2 = styled.div`
color:#599B31;
font-family:Times;
font-weight:bold;
font-style:italic;
font-size:200px;

align-self:flex-start;
justify-self:flex-start;
line-height: 10px;
  padding-top: 40px;
  padding-right:20px;

  

`;

const Byline1 = styled.div`
color:${Constants.TEXT_COLOR};
font-size:17px;

`;

const Byline2 = styled.div`
color:#599B31;
font-size:15px;
`;

const Image = styled.img`
width:75%;
filter: drop-shadow(1px 1px 1px #222222);
@media (max-width:${Constants.SM}px) {
    width:100px;
    height:auto;
    margin-right:10px;
    
  
}


`;

const ImageHolder = styled.div`

@media (max-width:${Constants.SM}px) {
${(props) => props.mode === '2' && `
position:absolute;
margin-top:100px;
margin-left:-50px;
`}
}`;


const ByLineHolder = styled.div`

align-items:flex-end;
justify-content:${(props) => (props.mode === '1' ? 'flex-end' : 'flex-start')};
padding-top:10px;
width:100%;
display:flex;
`;


const AllTextHolder = styled.div``;


const Bylines = styled.div`

`;

export default () => {
  const content = useContext(PageContext);
  if (!content.sayings) {
    return null;
  }

  return (
    <Container>
      <Sayings>
        {content.sayings.map((saying) => (
          <Saying mode={saying.style} key={saying.by1}>

            <Holder>
              <TextHolder>
              {saying.style === '1' && (
                    <BigQuote>
                      “
                    </BigQuote>
                  )}
                <Text>
                  
                  <AllTextHolder>
                    {saying.text}

                    <ByLineHolder mode={saying.style}>
                      <Bylines>
                        <Byline1>
                          {saying.by1}
                        </Byline1>
                        <Byline2>
                          {saying.by2}
                        </Byline2>
                      </Bylines>
                    </ByLineHolder>
                  </AllTextHolder>


                </Text>
                {saying.style === '2' && (
                  <BigQuote2>
                    ”


                  </BigQuote2>
                )}
              </TextHolder>

            </Holder>


            <ImageHolder mode={saying.style}>
              <Image src={`${ASSETS_SERVER}/images/${saying.image}`} />
            </ImageHolder>
          </Saying>
        ))}
      </Sayings>
    </Container>
  );
};
