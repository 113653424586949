import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ASSETS_SERVER } from '../../lib/Api';
import {
  MoreButtonHolder, MoreButton, Container, TitleText, GradientButton,
} from '../Common';
import Constants, { Title2, TextBody, TextLink } from '../Constants';
import Anchor from '../Anchor';
import RightArrow from '../../../assets/right-chevron.png'

const BoxSection = styled.div`
    padding-bottom:40px;
    padding-top:40px;
    margin:auto;
  max-width:${Constants.LG}px;
  padding-right:10px;
  padding-left:10px;

`;
const Title = styled.div`
font-size:40px;
color:${Constants.TEXT_COLOR};
font-weight:bold;
margin-bottom:40px;
`;


const Boxes = styled.div`
display:flex;
justify-content:space-around;
flex-wrap:wrap;
 
 

`;

const BoxDiv = styled.div`
${(props) => (props.gallery ? '' : `background-color:#ffffff;
border-radius:8px;
border-style:solid;
border-width:1px;
border-color:#919294;
 align-items:center;
max-width:${346}px;
height: ${(props.bt ? 'auto' : '400px')};
padding:10px;
display:flex;
flex-direction:column;
justify-content:space-between;
margin-top:10px;
margin-bottom:10px;`)}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) 
{
  flex:none;
}

`;


const Image = styled.img`
max-width:100%;
height:auto;
`;

const ImageHolder = styled.div`
display:flex;
align-items:center;
justify-content:center;

`;

const BoxTitle = styled.div`
color:${Constants.TEXT_COLOR};
font-size:25px;
font-weight:bold;
text-align:center;
padding-top:15px;
padding-bottom:15px;
${Title2}
${(props) => (props.gt ? 'font-size:22px;' : '')}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) 
{
  max-width:300px;
}

 

`;

const BoxText = styled.div`

color:${Constants.TEXT_COLOR};
text-align:center;
margin-bottom:10px;
color:${(props) => (props.bt ? Constants.TEXT_COLOR : '#2BB573')};
${(props) => (props.bt ? TextBody : Title2)}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) 
{
  max-width:300px;
}

`;

const RightArrowButton=styled.img`
height:12px;
margin-left:4px;
margin-top:-1px; 
`;

const BoxButton = styled(Anchor)`
text-decoration:none;
color:${Constants.HYPER_LINK_COLOR};
${TextLink};
margin-bottom:10px;
margin-top:10px;
  
align-items:center;
display:flex;
::after
{
  content: "";
  font-family: "Optum-Micro-interaction-16";
  display: inline-block;
  font-size: 0.75em;
  font-weight: bold;
  padding-left: 2px;
  vertical-align: middle;
}





`;


const SubTitle = styled.div`
margin-bottom:40px;
text-align:center;
color:${Constants.TEXT_COLOR};
${TextBody}
`;

const ButtonHolder = styled.div`
width:100%;
display:flex;
justify-content:center;
align-items:flex-end;

 
 
`;

const Bar = styled.hr`
width:50%;
height:2px;
background-color:#767676;

`;



const Box = ({
  box, gt, bt, gallery,
}) => (
  <BoxDiv gallery={gallery} gt={gt} bt={bt}>
    <div>
      <ImageHolder>
        <Anchor to={`${box.link_name}`}>
          <Image src={`${ASSETS_SERVER}/images/${box.image}`} />
        </Anchor>
      </ImageHolder>
      {gt && <Bar />}
      {box.title && <BoxTitle gt={gt} bt={bt} dangerouslySetInnerHTML={{ __html: box.title }} />}

      {box.text && <BoxText gt={gt} bt={bt} dangerouslySetInnerHTML={{ __html: box.text }} />}
    </div>

    {!gallery && (
    <ButtonHolder>
      {box.link_name && <BoxButton gt={gt} bt={bt} title={box.link_text} to={`${box.link_name}`}>{box.link_text}</BoxButton>}
    </ButtonHolder>
    )}

  </BoxDiv>
);


export default ({
  section, gt, bt, gallery, level2,
}) => {
  const shouldMore = gallery || level2;

  const [tempBoxes, setTempBoxes] = useState(section.boxes.slice(0, shouldMore ? section.boxes.length : Math.min(3, section.boxes.length)));

  const hasMore = section.boxes.length > 3;

  const doMore = () => {
    setTempBoxes(section.boxes);
  };

  const doLess = () => {
    setTempBoxes(section.boxes.slice(0, Math.min(3, section.boxes.length)));
  };


  return (

    <BoxSection>
      <TitleText dangerouslySetInnerHTML={{ __html: section.title }} />
      {section.subtitle && <SubTitle dangerouslySetInnerHTML={{ __html: section.subtitle }} />}
      <Boxes>
        { bt && tempBoxes.map((box) => <div><Box gallery={gallery} gt={gt} bt={bt} key={box.name} box={box} /></div>)}
        { !bt && tempBoxes.map((box) => <Box gallery={gallery} gt={gt} bt={bt} key={box.name} box={box} />)}
      </Boxes>
      {!shouldMore && (
      <MoreButtonHolder>
        {hasMore && tempBoxes.length <= 3 && <MoreButton color="#929292" bg="#F3F3F3" border="#BEBEBE" onClick={doMore}>More</MoreButton> }
        {hasMore && tempBoxes.length > 3 && <MoreButton color="#929292" bg="#F3F3F3" border="#BEBEBE" onClick={doLess}>Less</MoreButton> }
      </MoreButtonHolder>
      )}
      {section.button_text && (
      <MoreButtonHolder>
        <GradientButton to={section.button_link} style={{ maxWidth: 300 }}>{section.button_text}</GradientButton>

      </MoreButtonHolder>
      )}

    </BoxSection>

  );
};
