import React, { useContext } from 'react';
import styled from 'styled-components';
import { HashLink as Link } from 'react-router-hash-link';
import { PageContext } from './App';
import { ASSETS_SERVER } from '../lib/Api';
import Breathing from './Breathing';
import Constants from './Constants';
import Alerts from './Alerts';
import { GradientButton, Container, ContentHolder } from './Common';
import Anchor from './Anchor';

const TopSection = styled.div`
    margin-left:auto;
    margin-right:auto;
    background-repeat:no-repeat;
    background-size:auto 100%;
    background-position:center;
    background-color:#ffffff;
  
    background-image:url(${() => `${ASSETS_SERVER}/images/LFTFL_HeroBanner.png`});
  
    @media (max-width:${Constants.SM}px) {
    background-repeat:no-repeat;
    background-size:auto 100%;
    background-image:url(${() => `${ASSETS_SERVER}/images/LFTFL_HeroBanner_mobile.png`});
    display:flex;
    flex-direction:column;
    
    }
    

    
`;


const MenuContainer = styled.div`
 
`;

const MenuHeader = styled(Anchor)`
background-color:#599B31;
height:65px;
display:flex;
justify-content:center;
align-items:center;
text-decoration:none;
font-weight:bold;

color:${Constants.WHITE}

width:350px;
@media (max-width:${Constants.XS}px) {
  width:220px;
  
  
}
`;

const Menu = styled.div`
width:350px;
@media (max-width:${Constants.XS}px) {
  width:220px;
  
  
}
`;

const MenuItem = styled(Anchor)`
color:${Constants.TEXT_COLOR};
height:65px;
display:flex;
justify-content:center;
align-items:center;
background-color:${Constants.WHITE};
border-color:#F3F3F3;
border-width:.5px;
border-style:solid;
text-decoration:none;
border-bottom-width:1px;
transition:background-color .5s linear,
            color .5s ease;
:hover{
    background-color:#eeeeee;
    color:#606060;
}
`;


const WidgetContainer = styled.div`
  
  display:flex;
  flex:1;
  margin-left:20px;
  flex-wrap:wrap;
  min-height:400px;
  min-width:200px;
  background-image:url(${ASSETS_SERVER}/images/top_girl.png);
  background-repeat:no-repeat;
  background-size:auto 398px;
   @media (max-width:${Constants.LG}px) {
    margin-left:0px !important;
    width:100%;
    
    
    
 
    
    
  
}


`;


const BreathingHolder = styled.div`
display:flex;
padding:20px;
flex:1;
justify-content:center;
align-items:center;  


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) 
{
  padding:80px;
}


 @media (max-width:${Constants.LG}px) {
    margin-left:0px;
    padding:0px;

  
  
}
@media (max-width:${Constants.XS}px) {
    margin-left:0px;
    padding:0px;
 
  
  
}
 
`;

const EmptyDiv = styled.div`
flex:1;
@media (max-width:${Constants.SM}px) {
flex:0;
display:none;
width:0px;


  
}
`;

const Image=styled.img`
  @media (max-width:${Constants.SM}px) {
    align-self:center;
    width:80%;
    height:80%;
      
    }
`;

const ImageContentHolder=styled(ContentHolder)`
  padding-bottom:40px;
  @media (max-width:${Constants.SM}px) {
      display:flex;
      justify-content:center;
      align-self:center;
      padding:20px;
      padding-bottom:40px;
      
      
    }
`;

export default ({children}) => {
  


  return (


    <TopSection id="mainImage">
       <a id="top" />

      <Alerts />

      <ImageContentHolder>
        <Image src={`${ASSETS_SERVER}/images/LFF-Logo-Lockup-RGB_v2.png`}/>
{false &&        <img src={`${ASSETS_SERVER}/images/LFTFL_HeroBanner.png`} style={{visibility:'hidden'}} />}
      </ImageContentHolder>
      {children}
    </TopSection>

  );
};
