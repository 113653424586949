import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Container, ContentHolder, TitleText } from './Common';
import Constants from './Constants';
import { PageContext } from './App';
import { ASSETS_SERVER } from '../lib/Api';

const TYContentHolder = styled(ContentHolder)`
    padding:20px;
    padding-left:40px;
    padding-right:40px;
    display:flex;
    justify-content:center;
    align-items:center;

`;


const EmText = styled.span`
    font-family:${Constants.BRUSHSC};
    font-size:${Constants.TXT_SIZE_LG};
    color:${Constants.BLACK};
`;

const ThakYouList = styled.div`
display:flex;
width:100%;
align-items:center;
justify-content:space-between;
padding-top:10px;
flex-wrap:wrap;
`;

const Thank = styled.img`
max-width:120px;
`;

export default () => {
  const content = useContext(PageContext);
  if (!content.Thankyou) {
    return null;
  }
  return (
    <Container background={Constants.GREY_5}>
      <TYContentHolder>
        <TitleText>
          <EmText>Thank you! </EmText>
          Love for the Frontline Donors
        </TitleText>
        <ThakYouList>
          {content.Thankyou.map((thank) => (
            <a href={thank.link} target="_blank">

              <Thank src={`${ASSETS_SERVER}/images/${thank.image}`} />
            </a>
          ))}
        </ThakYouList>
      </TYContentHolder>
    </Container>
  );
};
