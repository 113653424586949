import React, { useContext } from 'react';
import styled from 'styled-components';
import { PageContext } from './App';
import { ASSETS_SERVER } from '../lib/Api';
import Constants, { TextAlert } from './Constants';
import { Container, TitleStyle1 } from './Common';
import Anchor from './Anchor';


const Footer = styled.div`
max-width:${Constants.LG}px;
margin:auto;
padding-top:40px;
padding-bottom:40px;
background-color:${Constants.WHITE};
`;


const FooterText = styled.div`
text-align:center;
${TextAlert}
padding:10px;
color:${Constants.TEXT_COLOR};

`;

const Logos = styled.div`
width:100%;
display:flex;
flex-wrap:wrap;
justify-content:center;
`;

const Logo = styled.img`

`;

const Link = styled(Anchor)`
margin-left:10px;
margin-right:10px;
`;

const Menu = styled(Anchor)`
margin-right:10px;
margin-left:10px;
text-decoration:none;
color:#1592E6;
font-size:13px;
:hover{
    color:#6596E6
}
`;

const MenuHolder = styled.div`
text-align:center;

`;

export default () => {
  const content = useContext(PageContext);


  return (
    <Container>
      <Footer>
        <TitleStyle1 style={{ marginBottom: 10 }}>{content.footer.title}</TitleStyle1>
        <Logos>
          <Link target="_blank" to={content.footer.optum_logo.link}><Logo src={`${ASSETS_SERVER}/images/${content.footer.optum_logo.image}`} /></Link>
        </Logos>
        <TitleStyle1 style={{ marginBottom: 10, marginTop: 30 }}>{content.footer.sub_title}</TitleStyle1>
        <Logos>
          {content.footer.logos.map((logo) => <Link key={logo.image} target="_blank" to={logo.link}><Logo src={`${ASSETS_SERVER}/images/${logo.image}`} /></Link>)}
        </Logos>
        <FooterText dangerouslySetInnerHTML={{ __html: content.footer.copyRightText }} />
        <MenuHolder>
          <Menu target="_blank" to={content.footer.Privacy}>Privacy</Menu>
          <Menu target="_blank" to={content.footer.Terms_of_Use}>Terms of Use</Menu>
          <Menu target="_blank" to={content.footer.Accessibility}>Accessibility</Menu>
        </MenuHolder>
        <br />
        <br />

      </Footer>
    </Container>
  );
};
