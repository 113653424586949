import React, {
  useContext,
  useEffect,
  createContext,
  useState
} from 'react';
import {
  PageContainer,
  GreenBar,
  HomeButton,
  ContentHolder
} from '../Common';
import TopSection from '../TopSection';
import Footer from '../Footer';
import {
  PageContext
} from '../App';
import L2VideoPage from './L2VideoPage';
import L2ContentPage from './L2ContentPage';
import Api from '../../lib/Api';
import Sections from '../Sections';
import styled from 'styled-components';
import {
  HashLink
} from 'react-router-hash-link';
import ToTopButton from '../ToTopButton';


const InnerPageContext = createContext({});

export {
  InnerPageContext
}

const HomeButtonHolder = styled(ContentHolder)
`
 height:30px;
 margin-top:-30px;
 
 
 

`;

export default (props) => {





  const content = useContext(PageContext);
  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    const pathName = props.location?.pathname?.substring(1);
    Api.getJsonContent(pathName).then((data) => {
      setPageContent(data);
      document.getElementById("L2top").scrollIntoView();
    }).catch(err => {
      console.log(err);
    });

  }, [props.location?.pathname])

  const getResultPage = () => {
    if (!pageContent) {
      return null;
    }

    if (pageContent.page_type == "l2_video")
      return <L2VideoPage / >
        if (pageContent.page_type == "l2_content")
          return <L2ContentPage / >


  }

  if (!pageContent) {
      return null;
    }

  return (
    <InnerPageContext.Provider value={pageContent}>
    <PageContainer  >
 
   {pageContent.page_type !== "l2_video" && <ToTopButton />}
      <TopSection >
      <a id="L2top"/>
        </TopSection>
        <HomeButtonHolder>
          <HashLink style={{textDecoration:"none"}} to="/#top">
          <HomeButton>Home</HomeButton>
          </HashLink>
        </HomeButtonHolder>

           <GreenBar />
      <div className="l2pages">
        {getResultPage()}
        </div>
    {pageContent?.sections && <Sections level2 content={pageContent} />}

      <GreenBar />
      <Footer />
    </PageContainer>
    </InnerPageContext.Provider>
  );
};