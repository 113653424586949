import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import Constants from './Constants';

import { PlainButton } from './Common';
import { ASSETS_SERVER } from '../lib/Api';

const FAB = styled(HashLink)`
 
 
position:fixed;
right:50px;
bottom:50px;
text-decoration:none;
text-align:center;
width:70px;
height:70px;
display:flex;
justify-content:center;
align-items:center;
 
cursor: pointer;


@media (max-width:${Constants.LG}px) {
  right:20px;
      
    }

:focus{
    outline:0;
}



`;

const Image = styled.img`
  width:70px;
height:70px;
@media (max-width:${Constants.LG}px) {
  width:70px;
height:70px;
      
    }
`;

export default () => {
  const [hide, setHide] = useState(true);

  useEffect(
    () => {
      try {
        const observer = new IntersectionObserver(((entries) => {
          setHide(entries[0].isIntersecting === true);
        }), { threshold: [1] });

        observer.observe(document.querySelector('#mainImage'));
      } catch (err) {
  
        setHide(false);
      }
    }, [],

  );


  if (hide) {
    return null;
  }
  return (
    <FAB smooth to="#top" tabIndex={0}>
      <Image src={`${ASSETS_SERVER}/images/to_top.png`} />

    </FAB>
  );
};
