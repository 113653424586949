import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { PageContext } from './App';
import Constants, { TextAlert } from './Constants';
import { PlainButton } from './Common';


const Alert = styled.div`
margin-left:auto;
margin-right:auto;
display:flex;
justify-content:center;
background-color:${Constants.GREY_5};
padding:20px;


`;

const AlertText = styled.div`
text-align:center;
max-width:${Constants.LG - 40}px;
color:${Constants.GREY_6};
${TextAlert}
`;

const ButtonHolder = styled.div`
margin-left:auto;
margin-right:auto;
display:flex;
flex-wrap:wrap;
justify-content:flex-end;
max-width:${Constants.LG}px;
`;

const AlertButton = styled(PlainButton)`
    background-color:${(props) => (props.selected ? Constants.TITLE_COLOR : Constants.GREY_3)};
    color:${Constants.WHITE};
  
    min-width:250px;
    text-align:center;
    padding:10px;    
    padding-right:10px;
    padding-left:10px;
    cursor: pointer;
    ${TextAlert}
    @media (max-width:${Constants.SM}px) {
      min-width:auto;
      flex:1;
      
    }
    
`;


const AlertContainer = styled.div``;

export default () => {
  const content = useContext(PageContext);
  if (!content.alerts) {
    return null;
  }
  if (content.alerts.length === 0) {
    return null;
  }

  const [activeAlert, setActiveAlert] = useState(content.alerts[content.alerts.length - 1]);


  return (
    <AlertContainer>
      <Alert>
        <AlertText className="alert" dangerouslySetInnerHTML={{ __html: activeAlert.text }} />
      </Alert>

      <ButtonHolder style={{ marginBottom: content.alerts.length > 1 ? 0 : 40 }}>
        {content.alerts.length > 1 && (
        <>
          {' '}
          { content.alerts.map((alert) => (
            <AlertButton
              onClick={() => setActiveAlert(alert)}
              selected={activeAlert.title === alert.title}
              key={alert.title}
            >
              {alert.title}
            </AlertButton>
          ))}
        </>
        )}
      </ButtonHolder>
    </AlertContainer>
  );
};
