/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { PageContext } from './App';
import LongText from './sections/LongText';
import BoxSection from './sections/BoxSection';
import BoxOutLine from './sections/BoxOutLine';
import Constants from './Constants';
import RoundBoxType from './sections/RoundBoxType';


const Sections = styled.div`

`;

const Section = styled.div`
background-color:${(props) => props.bgcolor || '#ffffff'};
`;


export default ({ content, level2 }) => {
  if (!content) {
    return null;
  }


  const getSelection = (section) => {
    switch (section.section_type) {
      case 'longtext':
        return <LongText section={section} />;
      case 'box':
        return <BoxSection level2={level2} section={section} />;
      case 'box_outline':
        return <BoxOutLine level2={level2} section={section} />;
      case 'round-box-gt':
        return <RoundBoxType level2={level2} gt section={section} />;
      case 'round-box-bt':
        return <RoundBoxType level2={level2} bt section={section} />;
      case 'gallery':
        return <RoundBoxType level2={level2} gallery section={section} />;
      default:
        return null;
    }
  };


  return (
    <Sections>
      {content.sections.map((section) => (
        <Section bgcolor={section.bgcolor} key={section.name}>
          <a id={section.name}>
            {getSelection(section)}
          </a>
        </Section>
      ))}
    </Sections>
  );
};
