import React, { useContext } from 'react';
import styled from 'styled-components';
import { PageContext } from './App';
import {
  Container, ContentHolder, TitleText, GradientButton,
} from './Common';
import { ASSETS_SERVER } from '../lib/Api';
import Constants from './Constants';
import { Link } from 'react-router-dom';


const SharePhotoHolder = styled(ContentHolder)`
    padding:40px;
 
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;

    
    @media  (max-width:${Constants.SM}px) {
      padding-left:0px;
    padding-right:0px;
    }

`;

const Image = styled.img`
 margin:8px;
 max-width:900px;
 margin-top:10px;
 
 @media (max-width:${Constants.SM}px) {
     width:100px;
     
 }
 
`;

const ButtonHolder = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    padding-bottom:20px;
`;

const ImageRow=styled.div`
display:flex;
 flex:1;
align-items:center;
justify-content:space-around;
 flex-wrap:wrap;
 width:100%;
 

`;

export default () => {
  const content = useContext(PageContext);
  if (!content.Gallery) {
    return null;
  }
  return (


    <Container>
      <SharePhotoHolder>
        <TitleText>
          {content.Gallery?.title}
        </TitleText>
            <Link to={content.Gallery.link_name}>
          <ImageRow>
       {content.Gallery?.images.map(image=>{
        return <Image src={`${ASSETS_SERVER}/images/${image}`} />
       })}
       </ImageRow>
       </Link>
      </SharePhotoHolder>

    </Container>
  );
};
